import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { Box } from "@mui/system";
import { CreateHeader, CreateProperty, setGlobalVal } from "./common-tool";

var head_order = { 'absract': 0, 'header': 1, 'stat': 2, 'about': 3 }

async function search_single_asx(query, callback_fn) {
    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "quickbook_query", { "name": query }
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ASX database that we collected." };
                        callback_fn(result);
                        return;
                    }

                    let keys = Object.keys(result);
                    result.absract = {};

                    keys.forEach(key => {
                        console.log(`key=${key} list=${Object.keys(head_order)}`)
                        if (Object.keys(head_order).indexOf(key) === -1) {
                            let cont = result[key];
                            delete result[key]
                            result.absract[key] = cont;
                        }
                    })
                    callback_fn(result);
                    return;
                }
                else {
                    result = { 'error': "Unknowned error." };
                    callback_fn(result);
                    return;
                }

            }).catch((err)=>{
                alert(`No data found for ${query}`)
                console.log(err)
            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

async function search_asx(query, callback_fn) {
    var first_query = "", first_result = {};
    for (var i = 0; i < query.length; i++) {
        let qi = query[i];
        let result = await new Promise(resolve => search_single_asx(qi, resolve));
        if (result.error) {
            if (i === 0) {
                first_query = qi;
                first_result = result;
            }
            continue;
        }
        console.log(JSON.stringify(result))
        callback_fn(qi, result);
        return;
    }
    callback_fn(first_query, first_result)
}


export function QuickBook(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState([]);

    useEffect(() => {
        var q = "";
        if (state.entityName.length > 0) {
            q = state.entityName
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }

        setQuery(q);

    }, [state.entityName, query, dispatch, state.acnResult, state])

    useEffect(() => {
        if (query.length === 0) return;
        if (state.asxHistory.query in query) {
            setContent(state.asxHistory.result)
            setSpin(false);
            return;
        }
        setSpin(true);

        search_asx(query, (qi, result) => {
            dispatch({
                asxHistory: { query: qi, result: result }
            })
            setContent(result);
            setSpin(false);

        })
    }, [query])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        return (
            <div>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            QuickBook Search Result
                        </Typography>
                    </Grid>


                    <Grid item xs={12}>
                        <Paper sx={{ p: 4 }}>
                            {
                                content.error ?
                                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Info</AlertTitle>
                                            {content.error}
                                        </Alert>
                                    </Paper>
                                    :
                                    CreateHeader(content, head_order)
                            }

                        </Paper>
                    </Grid>
                </Grid>

            </div>

        )
    }
}