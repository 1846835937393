import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export function ComboBox(props) {
    
    return (
            <FormControl
                {...props.style}
                sx={{
                width: '100%'
                }}
                size="small"
                disabled={props.disabled||false}
            >
                { props.label &&
                    <InputLabel id="demo-simple-select-disabled-label">{props.label}</InputLabel>
                }
                <Select
                  value={props.value}
                  label={props.label}
                  onChange={(event) => {
                    const newVal = event.target.value;
                    //setValue(newVal);
                    props.onChange(newVal);
                  }}
                >
                {
                    props.list.map((e, index) => (
                    <MenuItem
                        value={e}
                        key={index}
                    >
                        <Typography
                        color="textPrimary"
                        variant="body2"
                        >
                        {props.keyfield?e[props.keyfield]:e}
                        </Typography>
                    </MenuItem>
                    ))
                }
                </Select>
            </FormControl>
    )
}
