import { useEffect, useState } from "react";
import bgbanner from "../assets/bg-banner.jpg";
import axios from "axios";
import { BSEARCH_URI } from "../common/config";
import DataModal from "./dataModal";
import Loader from "./loader";

export default function BSearch1() {
  const db_list = [
    { name: "all", title: "ALL DB", symbol: "all" },
    { name: "composite-listing", title: "Composite Listing", symbol: "composite Listing" },
    // { name: "accziom", title: "ACCZIOM DB" },
    { name: "abn_query", title: "ABR DB", category: "A" },
    { name: "tpb_query", title: "TPB DB", symbol: "tpb", category: "A" },
    { name: "verify", title: "ASIC PAID DB", category: "A" },

    { name: "acn_query", title: "ASIC DB", category: "B" },
    { name: "asx_query", title: "ASX DB", symbol: "asx", category: "B" },
    { name: "lei_query", title: "LEI DB", symbol: "lei", category: "B" },

    {
      name: "quickbook_query",
      title: "Quickbook DB",
      symbol: "quickbook",
      category: "C",
    },
    { name: "xero_query", title: "Xero DB", symbol: "xero", category: "C" },
    {
      name: "ca_query",
      title: "Chartered Accountant",
      symbol: "ca",
      category: "C",
    },
    {
      name: "pa_query",
      title: "Public Accountant",
      symbol: "pa",
      category: "C",
    },
    { name: "bing_query", title: "Bing Query", symbol: "bing", category: "C" },
  ];
  const [selectedDB, setSelectedDb] = useState( { name: "abn_query", title: "ABR DB"  , category :'A'});
  const [query, setQuery] = useState({});
  const [selectedRow, setSelectedRow] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const filters = {
    "composite-listing": [
      {
        type: "text",
        placeholder: "Query",
        id: "query",
        label: "Please enter the query",
      },
    ],
    pa_query: [
      {
        type: "text",
        placeholder: "name",
        id: "name",
        label: "Please enter the search name",
      },
    ],
    abn_query: [
      // {
      //   type: "text",
      //   placeholder: "name",
      //   id: "name",
      //   label: "Please enter the search name",
      // },
      {
        type: "text",
        placeholder: "abn /Name",
        id: "query",
        label: "Please enter the abn / Name",
      },
    ],
    acn_query: [
      {
        type: "text",
        placeholder: "abn / acn / name",
        id: "query",
        label: "Please enter the abn / acn or name",
      },
    ],
    all: [
      {
        type: "text",
        placeholder: "text / ABN",
        id: "query",
        label: "Please enter the search query",
      },
    ],
    bing_query: [
      {
        type: "text",
        placeholder: "query",
        id: "query",
        label: "Please enter the search query",
      },
      {
        type: "text",
        placeholder: "Locality",
        id: "locality",
        label: "Please enter the locality",
      },
    ],
    asx_query: [
      {
        type: "text",
        placeholder: "name",
        id: "name",
        label: "Please enter the name",
      },
      {
        type: "text",
        placeholder: "industry name",
        id: "industry_name",
        label: "Please enter the name of industry",
      },
    ],
    lei_query: [
      {
        type: "text",
        placeholder: "name",
        id: "name",
        label: "Please enter the name",
      },
      {
        type: "text",
        placeholder: "city",
        id: "city",
        label: "Please enter the city",
      },
      {
        type: "number",
        placeholder: "postal code",
        id: "pcode",
        label: "Please enter the postal code",
      },
    ],
    tpb_query: [
      {
        type: "text",
        placeholder: "practitioner name",
        id: "legal_name",
        label: "Please enter the practitioner name",
      },
      {
        type: "number",
        placeholder: "ABN",
        id: "abn",
        label: "Please enter the abn",
      },
      {
        type: "text",
        placeholder: "State",
        id: "state",
        label: "Please enter the state",
      },
      {
        type: "text",
        placeholder: "suburb",
        id: "suburb",
        label: "Please enter the suburb",
      },
    ],
    xero_query: [
      {
        type: "text",
        placeholder: "name",
        id: "name",
        label: "Please enter the name",
      },
      {
        type: "number",
        placeholder: "postal code",
        id: "pcode",
        label: "Please enter the postal code",
      },
      {
        type: "text",
        placeholder: "state",
        id: "state",
        label: "Please enter the state",
      },
    ],
    quickbook_query: [
      {
        type: "text",
        placeholder: "company name",
        id: "company_name",
        label: "Please enter the company name",
      },
      ,
      {
        type: "text",
        placeholder: "state",
        id: "state",
        label: "Please enter the state",
      },
      {
        type: "number",
        placeholder: "postal code",
        id: "pcode",
        label: "Please enter the postal code",
      },
    ],
    ca_query: [
      {
        type: "text",
        placeholder: "name",
        id: "name",
        label: "Please enter the name",
      },
      {
        type: "text",
        placeholder: "state",
        id: "state",
        label: "Please enter the state",
      },
      {
        type: "number",
        placeholder: "postal code",
        id: "pcode",
        label: "Please enter the postal code",
      },
    ],
    verify: [
      {
        type: "text",
        placeholder: "abn or acn",
        id: "query",
        label: "Please enter the abn or acn",
      },
    ],
  };

  const dataFilter = {
    all: [{ name: "Symbol", value: "symbol" }],
    asx_query: [
      { name: "Symbol", value: "symbol" },
      { name: "Display Name", value: "displayName" },
      { name: "Industry", value: "industry" },
    ],
    tpb_query: [
      { name: "Pactitioner Name", value: "legal_name" },
      { name: "Practitioner Type", value: "practitioner_type" },
      { name: "State", value: "state" },
      { name: "suburb", value: "suburb" },
    ],
    lei_query: [
      { name: "Legal Name", value: "LegalName" },
      { name: "Postal Code", value: "PostalCode" },
      { name: "City", value: "City" },
    ],
    xero_query: [
      { name: "Name", value: "Name" },
      { name: "Postal Code", value: "Pcode" },
      { name: "State", value: "State" },
      { name: "Address", value: "Address" },
    ],
    quickbook_query: [
      { name: "Name", value: "company_name" },
      { name: "Postal Code", value: "pcode" },
      { name: "State", value: "state" },
    ],
    ca_query: [
      { name: "Name", value: "Company_Name" },
      { name: "Postal Code", value: "Postal_Code" },
      { name: "State", value: "State" },
      { name: "Address", value: "Company_Address" },
    ],
    bing_query: [
      { name: "Snippet", value: "Snippet" },
      { name: "Url", value: "Url" },
    ],
    pa_query: [{ name: "Name", value: "fa_company" }],
    abn_query: [
      { name: "Name", value: "Entity Name" },
      { name: "ABN", value: "ABN" },
      { name: "Postal Code", value: "Post Code" },
      { name: "State", value: "State" },
    ],
    acn_query: [
      { name: "Name", value: "Company Name" },
      { name: "ACN", value: "ACN" },
      { name: "ABN", value: "ABN" },
      { name: "Type", value: "Type" },
    ],
    verify: [
      { name: "Name", value: "Entity Name" },
      { name: "Type", value: "Entity Type" },
      { name: "Post Code", value: "Post Code" },
      { name: "State", value: "State" },
      { name: "Business Status", value: "Business Status" },
      { name: "Business Type", value: "Business Type" },
    ],
    "composite-listing" : [
      { name: "Entity Name", value: "name" },
      { name: "ABN", value: "abn" },
      { name: "Post Code", value: "postalCode" },
      { name: "State", value: "state" },
    ]
  };

  const filterFunction = (dbname, row, value) => {
    if (dbname == "lei_query") {
      if (value == "LegalName") return row?.Entity?.LegalName;
      if (value == "PostalCode") return row?.Entity?.LegalAddress?.PostalCode;
      if (value == "City") return row?.Entity?.LegalAddress?.City;
    }
  };

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [isOverallSearch, setIsOverallSearch] = useState(true);
  useEffect(() => {
    (selectedDB?.name !== "all" && selectedDB?.name !== "composite-listing") && applyFilters();
  }, [offset]);

  useEffect(() => {
    const element = document.getElementsByTagName("input");

    if (element) {
      for (let i = 0; i < element.length; i++) {
        element[i].value = "";
      }
    }
    setTotalPages(0);
    applyFilters();
    setOffset(1);
  }, [selectedDB]);
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(
        BSEARCH_URI +
          `db_list?type=${selectedDB?.symbol}&start=${offset}&count=${limit}`
      )
      .then((res) => {
        setIsOverallSearch(true);
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((Err) => {
        setData([]);
        setTotalLength(0);
        setTotalPages(0);
        setIsLoading(false);
      });
  };

  const applyFilters = (isQuery) => {
    
    setIsLoading(true);
    try {
      query.page = isQuery ? 1 : offset;
      isQuery && setOffset(1);
      const url =
        selectedDB?.name === "all"
          ? `https://backend.bsearchau.accziom.com:8002/search`
          : "https://backend.bsearchau.accziom.com:8002/" + selectedDB?.name;
      selectedDB?.name == "all"
        ? (query.db = "all")
        : query?.db && delete query?.db;
      const resp = axios
        .post(url, query)
        .then((result) => {
          if (result?.status == 200) {
            if (selectedDB?.name == "all") {
              setData(result?.data);
              setTotalLength(result?.data?.length || 0);
              setTotalPages(Math.round(result?.data?.length / limit) || 0);
              setIsOverallSearch(true);
              setIsLoading(false);
            } else {
              try {
                if (
                  selectedDB?.name == "verify" ||
                  selectedDB?.name == "bing_query"
                ) {
                  const dataarray = [result?.data];
                  setData(dataarray);

                  setTotalLength(1);
                  setTotalPages(1);
                  setIsLoading(false);
                } else if (selectedDB?.name == "abn_query") {

                  setData(result?.data?.data);
                  setTotalLength(result?.data?.numberOfRecords || 1);
                  setTotalPages(Math.ceil(result?.data?.numberOfRecords / limit) || 1);

                  // result?.data?.data?.length > 1 &&  setData((prev)=>{
                  //     return{...prev , ['name'] : query?.query}
                  //   })
                  
                } 
                else if(selectedDB?.name == "composite-listing"){
                 
                  setData(result?.data);
                  setTotalLength(result?.data?.length || 1);
                  setTotalPages(Math.ceil(result?.data?.length / limit )|| 1);
                  setIsLoading(false);
                }
                else {
                  setData(result?.data?.data);
                  setTotalLength(result?.data?.totalRecords || 1);
                  setTotalPages(result?.data?.totalPages || 1);
                  setIsLoading(false);
                  
                }
                setIsOverallSearch(false);
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log("🚀 ~ .then ~ error:", error);
              }
            }
          } else {
            setData([]);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("🚀 ~ axios.post ~ err:", err);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };
  const resetFilters = () => {
    const element = document.getElementsByTagName("input");
    console.log("🚀 ~ useEffect ~ element:", element);

    if (element) {
      for (let i = 0; i < element.length; i++) {
        element[i].value = ""; // Access the element using square brackets
      }
    }
    setQuery({});
  };

  const handleInputFields = (e) => {
    if (selectedDB?.name == "bing_query" && e.target.id == "query") {
      const data = [e.target.value];
      setQuery((prev) => {
        return { ...prev, [e.target.id]: data };
      });
    } else {
      setQuery((prev) => {
        return { ...prev, [e.target.id]: e.target.value };
      });
    }
  };

  const handlePageClick = (page) => {
  
    setOffset(page);
  };
  return (
    <>
      {isLoading && <Loader />}
      <DataModal
        data={selectedRow}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <section className="bsearch1-wrapper">
        <div className="position-relative banner-wrapper">
          <img src={bgbanner} alt="banner" className="img-fluid" />
          <div className="position-absolute banner-content">
            <h3 className="fs-4">IS YOUR DATABASE REGISTERED?</h3>
            <p>Check if your database is registered with us</p>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card top-card">
                <div className="card-body">
                  <h5 className="mb-2 fw-bold fs-6">Databases</h5>

                  <div className="d-flex flex-wrap gap-3">
                    {db_list?.map((db, index) => {
                      return (
                        <div
                          style={
                            db?.category == "A" && selectedDB?.name !== db.name
                              ? {
                                  backgroundColor: "#a4abb3",
                                  cursor:
                                    db?.name == "verify"
                                      ? "not-allowed"
                                      : "pointer",
                                }
                              : db?.category == "B" &&
                                selectedDB?.name !== db.name
                              ? {
                                  backgroundColor: "#CED6E0",
                                  cursor: "pointer",
                                }
                              : selectedDB?.name !== db.name
                              ? {
                                  backgroundColor: "#eceeef",
                                  cursor: "pointer",
                                }
                              : { background: "#2895EB" }
                          }
                          className={`card ${
                            selectedDB?.name == db.name ? "active" : ""
                          } `}
                          key={index}
                          onClick={() => {
                            if (db?.name == "verify") {
                            } else {
                              setQuery({});
                              setSelectedDb(db);
                            }
                          }}
                        >
                          <div className="card-body">{db.title}</div>
                        </div>
                      );
                    })}

                    {/* <div className="card bg-primary-subtle">
                      <div className="card-body">Third Party DB 1</div>
                    </div>
                    <div className="card bg-primary-subtle">
                      <div className="card-body">Third Party DB 2</div>
                    </div>
                    <div className="card bg-primary-subtle">
                      <div className="card-body">Third Party DB 3</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="fw-bold fs-6 mb-0">Filters</h5>
                  <div className="row gx-2 gy-1">
                    {filters[selectedDB?.name] &&
                      filters[selectedDB?.name]?.map((ele, index) => {
                        return (
                          <div className="col-md-4">
                            <div>
                              <label
                                for="exampleFormControlInput1"
                                className="form-label mb-1 fs-14"
                              >
                                {ele?.label}
                              </label>
                              <input
                                type={ele?.type}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    applyFilters(true);
                                  }
                                }}
                                className="form-control form-control-sm"
                                onChange={handleInputFields}
                                id={ele?.id}
                                placeholder={ele?.placeholder}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <button
                      className="btn btn-outline-primary btn-sm px-3"
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-primary btn-sm px-3  ms-2"
                      onClick={() => {
                        applyFilters(true);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              {!isOverallSearch ? (
                <div className="table-responsive">
                  <table className="table table-striped table-hover text-center align-middle">
                    <thead className="table-primary">
                      <tr>
                        {data &&
                          data?.length !== 0 &&
                          dataFilter[selectedDB?.name].map((key, index) => (
                            <th scope="col" key={index}>
                              {key.name}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                       
                        data?.map((row, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => {
                                setSelectedRow(row);
                                setShowModal(true);
                              }}
                            >
                              {dataFilter[selectedDB?.name].map(
                                (key, indexs) => {
                                
                                    if(selectedDB?.name == "composite-listing"){
                                      if ((index > offset && index < offset + limit) || data?.length < 9){
                                       
                                        return (
                                          <td key={indexs}>
                                            {typeof row[key.value] == "object"
                                              ? JSON.stringify(row[key.value])
                                              : row[key.value]}
                                          </td>
                                        );
                                      }
                                    
                                    }
                                    else{
                                      if (row[key?.value]) {
                                      return (
                                        <td key={indexs}>
                                          {typeof row[key.value] == "object"
                                            ? JSON.stringify(row[key.value])
                                            : row[key.value]}
                                        </td>
                                      );
                                    }
                                    else {
                                      const data = filterFunction(
                                        [selectedDB?.name],
                                        row,
                                        key?.value
                                      );
                                      return <td key={indexs}>{data}</td>;
                                    }
                                  
                                  } 
                                }
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <>No record found</>
                      )}
                      <tr></tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped table-hover text-center align-middle">
                    <thead className="table-primary">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">ABN / ACN</th>
                        <th scope="col">Postal Code</th>
                        <th scope="col">State / City</th>
                        <th scope="col">Data Source</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 &&
                        data?.map((entry, index) => {
                          if (index < offset * limit) {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  setQuery(entry);
                                }}
                              >
                                <td>{entry["name"]}</td>
                                <td>{entry['abn']}</td>
                                <td>{entry["postalCode"]}</td>
                                <td>{entry["state"]}</td>
                                <td>{entry["data source"]}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="flex">
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={() => {
                    offset > 1 && setOffset(offset - 1);
                  }}
                >
                  &lt;
                </button>{" "}
                {offset}{" "}
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={() => {
                    totalLength / limit > offset && setOffset(offset + 1);
                  }}
                >
                  &gt;
                </button>
              </div>
            </div>
            {selectedDB?.name !== "all" ? (
              <div>
                <b>total pages : </b>
                {totalPages}
              </div>
            ) : (
              <div>
                <b>total pages : </b>
                {Math.ceil(totalLength / limit)}
              </div>
            )}
            <div>
              <b>total records : </b>
              {totalLength}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
