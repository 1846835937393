import * as React from "react";
import { useGlobalState } from "./common/globalState";
import { injected, personal_sign } from "./common/metamask";
import { useWeb3React } from "@web3-react/core";
import { MERC_TOKEN_WS_URL } from "./common/config";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuListComposition from "./utils/menulist";
import { NoMetamaskDialog } from "./merc/components/noMetamask";
import Profile from "./profile";

export function AccountInfo() {
  const [activatingConnector, setActivatingConnector] = React.useState();
  const context = useWeb3React();
  const { connector, chainId, activate, error } = context;
  const [state, dispatch] = useGlobalState();
  const [logged, setLogged] = React.useState(false);
  const [signing, setSigning] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [webSocket, setWebSocket] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleClose = (value) => {
    setOpen(false);
  };

  const activating = injected === activatingConnector;
  const buttonText = activating ? "Connecting" : "Connect to Wallet";
  let connected = !error && chainId;

  const connectToTokenServer = () => {
    setSigning(true);
    setLogged(false);
    const ws = new WebSocket(MERC_TOKEN_WS_URL);
    ws.onmessage = async (e) => {
      let data = JSON.parse(e.data);
      let address = context.account;
      if (data.seed) {
        var sign_code = await personal_sign(data.seed, address);
        var output = {
          type: "register",
          seed: data.seed,
          address: address,
          sign_code: sign_code,
        };
        setSigning(false);
        ws.send(JSON.stringify(output));
        return;
      }
      console.log(data);
      setLogged(true);
      let bal = data.balance.layer2;
      let total_btc = data.btc_reserves.total_balance;
      dispatch({
        mrc2Balance: bal,
        mrcBalance: data.balance.layer1,
        btcReserveBalance: total_btc,
        mercReserves: data.mrc_reserves,
        exchangeBalance: data.exchanger_balance,
        totalSupply: data.mrc_total_supply,
        layer2Total: data.layer2_total,
        transactionHistory: data.transaction,
        btc_reserve_list: data.btc_reserve_info,
        mercBalance: data.balance,
        mercTransaction: data.transaction,
        nft: data.nft,
        mercStatistics: data.statistics,
        logged: true,
      });
    };
    ws.onopen = (e) => {
      console.log(`mrcaddress=${context.account}`);
      //ws.send(`${context.account}`)
      setWebSocket(ws);
    };
    ws.onclose = (e) => {
      console.log("closed ws server.");
      setLogged(false);
      setSigning(false);
      dispatch({
        logged: false,
      });
    };
  };

  React.useEffect(() => {
    setLogged(state.logged);
  }, [state.logged]);

  React.useEffect(() => {
    if (address !== context.account) {
      setAddress(context.address);
      if (logged) {
        webSocket.close();
        setWebSocket(null);
      }
      setLogged(false);

      dispatch({
        mercBalance: {},
        mercTransaction: [],
        nft: null,
        mercStatistics: {},
        logged: false,
      });
    }
  }, [context.account]);

  const handleSignIn = async (event) => {
    connectToTokenServer();
  };

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  React.useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true);
      }
    });
  }, []);

  const handleProfile = () => {
    dispatch({
      pane: "Profile",
    });
  };

  const handleWallet = () => {
    dispatch({
      pane: "Wallet",
    });
  };

  const handleNFT = () => {
    dispatch({
      pane: "NFT",
    });
  };

  if (connected) {
    let title = context.account;
    let menu = [
      {
        title: "Profile",
        handle: handleProfile,
      },
      {
        title: "Wallet",
        handle: handleWallet,
      },
      {
        title: "NFT",
        handle: handleNFT,
      },
    ]

    // [
    //   {
    //     title: "Sign In",
    //     handle: handleSignIn,
    //   },
    // ];

    title = title.substring(0, 5) + "..." + title.substring(title.length - 3);
    return (
      <>
        {/* <Badge variant="standard" color="secondary"> */}
        {/* <PersonIcon sx={{ color: logged?'white':'black' }}/> */}
        {/* <MenuListComposition title={title} menu={menu} color={logged?'white':'black'}/> */}
        {logged && menu.map((item, i) => (
          <Button
            key={item.title}
            sx={{ color: "#000", fontSize: "12px", fontWeight: 700 }}
            onClick={item.handle}
            disabled={signing}
          >
            {item.title}
          </Button>
        ))}
        {!logged && signing && (
          <Box display="flex" alignItems="center" ml="5px">
            <CircularProgress size={25} />
          </Box>
        )}
        {!signing && (
          <Profile onClick={handleSignIn} />
        )}
        {/* </Badge> */}
      </>
    );
  } else {
    if (activating) {
      return (
        <Box display="flex" alignItems="center" ml="5px">
          <CircularProgress size={25} />
        </Box>
      );
    } else {
      return (
        <>
          <Profile
            onClick={() => {
              if (window.ethereum === undefined) {
                setOpen(true);
                return;
              }
              if (!connected) {
                setActivatingConnector(injected);
                activate(injected);
              }
            }}
          />
          <NoMetamaskDialog open={open} onClose={handleClose} />
          {/* <Box color="inherit">
          <Grid container sx={{ textAlign: "center", width: "180px" }}>
            <Grid item xs={12}>
              <PersonIcon />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                sx={{ color: "orange" }}
                onClick={() => {
                  if (window.ethereum == undefined) {
                    setOpen(true);
                    return;
                  }
                  if (!connected) {
                    setActivatingConnector(injected);
                    activate(injected);
                  }
                }}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
          
        </Box> */}

        </>
      );
    }
  }
}
