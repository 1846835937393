import { Autocomplete, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Select, Stack, Table, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useGlobalState } from "../common/globalState";
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import LinkIcon from '@mui/icons-material/Link';
import MailIcon from '@mui/icons-material/Mail';
import CakeIcon from '@mui/icons-material/Cake';
import PhoneIcon from '@mui/icons-material/Phone';
import WebIcon from '@mui/icons-material/Web';
import { countries, getCountry } from "../utils/countries";

import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import SignpostIcon from '@mui/icons-material/Signpost';
import axios from "axios";
import { BSEARCH_URI, PROFILE_SERVER_URL } from "../common/config";
import { useWeb3React } from "@web3-react/core";
import { MintNFTView } from "../search/MintNFTView";
import { v4 as uuidv4 } from 'uuid';
import { Android12Switch, refine_html_text } from "../search/common-tool";

const personal_list = [
    {name: 'firstName', label: 'First Name', required:true},
    {name: 'middleName', label: 'Middle Name', required:false},
    {name: 'lastName', label: 'Last Name', required:true},
    {name: 'penName', label: 'Display Name', required:false},
    
];

const contact_list = [
    {name: 'email', label: 'EMail', required:false, icon:<MailIcon/>},
    {name: 'phone', label: 'Phone Number', required:false, icon:<PhoneIcon/>},
    {name: 'website', label: 'Website', required:false, icon:<WebIcon/>},   
];

const locate_list = [
    {name: 'addressLine1', label: 'Address Line 1', required:false, icon:<HomeIcon/>},
    {name: 'addressLine2', label: 'Address Line 2', required:false, icon:<ApartmentIcon/>},
    {name: 'city', label: 'City', required:false, icon:<LocationCityIcon/>},   
    {name: 'state', label: 'Region/State', required:false, icon:<LocationCityIcon/>},   
    {name: 'postalCode', label: 'Postal Code', required:false, icon:<SignpostIcon/>},   
];

function dateFormat(v) {
    let day = v.$D;
    let month = v.$M+1;
    let year = v.$y;
    let format1 = month + "/" + day + "/" + year;
    return format1; 
}

export function ProfileView(props) {
    const [state, dispatch] = useGlobalState();
    const [isSubmitting, setSubmitting] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [myURI, setURI] = useState('');
    const context = useWeb3React()

    const getUserInfo = async(address)=>{

        let result = await axios.post(PROFILE_SERVER_URL + 'personal', {
            address: context.account
        });
        let info = result.data;
        if (!info) info = {}; 
        if (!info.uri){
            let res = await axios.post(BSEARCH_URI + "get_new_uri", {
                prefix: "ps",
                folder: "person",
            })
            info.uri = res.data;
        }
        info.address = context.account;
        setUserInfo(info);
        setURI(info.uri);
    }

    const getRDFString = async () =>{
        let uri = userInfo.uri;
        if (uri === undefined || uri === "") return "";
        var id = uuidv4();
        let time = new Date();
        let time_str = time.toISOString();
        id += time_str
        var convert_table = {
            'firstName': 'givenName',
            'middleName': 'middleName',
            'lastName': 'familyName',
            'penName': 'displayName',
            'phone': 'phone',
            'website': 'url',
            'email': 'email',
            'dateOfBirth': 'birth'
        };

        let records = Object.keys(userInfo).map(e=>{
            if (convert_table[e]) {
                let prop = convert_table[e];
                let isPublic = getPublicCheck(e);
                return `
                    ${uri} azp:${prop} [
                        azp:value "${userInfo[e]}" ;
                        azpr:public "${isPublic?"Y":"N"}" ;
                        azpr:resource "${id}" ;
                    ]. 
                    `;
            }
            return '';
        });

        if (userInfo.addressLine1 && userInfo.addressLine1.trim() !== '') {
            let isPublic = getPublicCheck('addressLine1');
            var bus_addr = refine_html_text(userInfo.addressLine1);
            let res = await axios.post(BSEARCH_URI + "query_address", {
                params: {
                    address: bus_addr,
                }
            });
            res = res.data;

            if (res.entity) {
                var value = res.entity;
                
                if (res.detailed_address != '') {
                    records.push (
                        `
                            ${uri} azp:locateAt [
                                azp:value ${value} ;
                                azp:details <${res.detailed_address}>
                                azpr:public "${isPublic?"Y":"N"}" ;
                                azpr:resource "${id}" ;
                            ] .
                        `
                    );
                } else {
                    records.push(
                        `
                            ${uri} azp:locateAt [
                                azp:value ${value} ;
                                azpr:public "${isPublic?"Y":"N"}" ;
                                azpr:resource "${id}" ;
                            ] .
                        `
                    );
                }
                
            }    
        }
        let record = records.join('\n');
        if (record !== "") {
            record += `
                ${uri} azp:personalTimestamp [
                    azp:value "${time_str}" ;
                    azpr:resource "${id}" ;
                    azpr:public "Y" ;
                ] . 
            `
            console.log(record);
            return {id, time: time_str, record: record};
        }
        return '';
    }

    useEffect(()=>{
        getUserInfo(context.account);    
    }, [context.account])

    const handleChangeInfo = (field, v) => {
        setUserInfo({...userInfo,
            [field]: v.target.value
        })
    }

    const handleChangeCheck = (field, v) => {
        setUserInfo({...userInfo,
            [field+"__public"]: v.target.checked
        })
    }

    const getPublicCheck = (field, v) => {
        return userInfo[field+"__public"] || false;
    }

    const getValue = (field) => {
        return userInfo[field];
    }

    const exportToOntology = async () => {

    };

    const switchChanged = (field, v) => {
        console.log(v);
        setUserInfo({...userInfo,
            [field]: v,
        })
    }

    const handleSubmit = async (event) => {
        let id = await exportToOntology();
        let result = await axios.post(PROFILE_SERVER_URL + 'set_personal', {
            ...userInfo, id: id});
        if (result.error) {
            alert(result.error);
        }
    };

    if (state.logged === false) {
        return (
            <Container component={Paper} sx={{ mt: 4, mb: 4, display:props.show?'block':'none', minHeight:400}}>
                <Box position="sticky" top='20%'>
                <Typography >
                    Please sign in to explore your profile.
                </Typography>

                </Box>
            </Container>
        )
    } else {

        return (
            <Container component={Paper} direction="row"  sx={{ mt:4, display:props.show?'flex':'none', justifyContent:"center", width:"100%" }}>
                <Stack sx={{mt:4, mb:4}}>

                <Grid container
                    sx={{
                        maxHeight: '100%',              
                    }}
                >
                    <Grid item
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="100%"
                        sx={{
                            pl: 5,
                            pr: 1,
                            justifyContent:"center"
                        }}
                        xs={12}
                    >
                        <Typography
                        color="textSecondary"
                        variant="h5"
                        >
                            Profile
                        </Typography>
                    </Grid>
                    <Grid item xs={12}  sx={{top:"-30px", position:"relative"}}>
                        <Box display="flex" flexDirection="row-reverse">
                            {
                                props.back &&
                                <Button
                                    sx={{
                                        px: 5, ml: 2
                                    }}
                                    variant="contained"
                                    color="success"
                                    onClick={()=>{dispatch({pane: ''})}}
                                >
                                    Close
                                </Button>
                            }
                            <Button
                                sx={{
                                    px: 5
                                }}
                                variant="contained"
                                color="success"
                                startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                            
                        </Box>
                    </Grid>

                </Grid>
                <Grid 
                    container 
                    sx={{
                        maxHeight: '100%',
                        pr:10              
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 10}}>
                            <Typography
                                variant="h6"
                            >
                                URI
                            </Typography>
                            
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 15, mt: 2, mr: 1, mb:2}}>
                            <Typography
                                variant="subtitle1"
                            >
                                {myURI}
                            </Typography>
                            
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 10}}>
                            <Typography
                                variant="h6"
                            >
                                Personal
                            </Typography>
                            
                        </Box>
                    </Grid>
                    {
                        personal_list.map((e, index)=>(
                            <Grid item xs={12} key={index}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Box sx={{textAlign: "left", ml: 15, mt: 2, mr: 1}}>
                                            <TextField
                                                sx={{width: "100%"}}
                                                label={e.label}
                                                value={getValue(e.name) || ""}
                                                onChange={(v) => handleChangeInfo(e.name, v)}
                                                required={e.required}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sx={{alignSelf:'center'}}>
                                        <Box>
                                            <FormControlLabel
                                                control={<Android12Switch checked={getPublicCheck(e.name)} onChange={(v) => handleChangeCheck(e.name, v)}/>}
                                                label={getPublicCheck(e.name)?"Public":"Private"}
                                            />

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                                
                        ))
                    }
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Box sx={{textAlign: "left", ml: 15, mt: 2, mr: 1}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            sx={{width:"100%"}}
                                            label="Date of Birth"
                                            value={userInfo.dateOfBirth || "01/01/2000"}
                                            icon={CakeIcon}
                                            onChange={(newValue) => switchChanged('dateOfBirth', dateFormat(newValue))}
                                            renderInput={(params) => <TextField {...params} />}
                                        />

                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={2} sx={{alignSelf:'center'}}>
                                <Box>
                                    <FormControlLabel
                                        control={<Android12Switch checked={getPublicCheck('dateOfBirth')} onChange={(v) => handleChangeCheck('dateOfBirth', v)}/>}
                                        label={getPublicCheck('dateOfBirth')?"Public":"Private"}
                                    />

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 10, mt:4}}>
                            <Typography
                                variant="h6"
                            >
                                Contacts
                            </Typography>
                            
                        </Box>
                    </Grid>
                    {
                        contact_list.map((e, index)=>(
                            <Grid item xs={12} key={index}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Box sx={{textAlign: "left", ml: 15, mt: 2}}>
                                            <TextField
                                                sx={{width: "100%"}}
                                                label={e.label}
                                                value={getValue(e.name) || ""}
                                                onChange={(v) => handleChangeInfo(e.name, v)}
                                                required={e.required}
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        {e.icon}
                                                    </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sx={{alignSelf:'center'}}>
                                        <Box>
                                            <FormControlLabel
                                                control={<Android12Switch checked={getPublicCheck(e.name)} onChange={(v) => handleChangeCheck(e.name, v)}/>}
                                                label={getPublicCheck(e.name)?"Public":"Private"}
                                            />

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                                
                        ))
                    }
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 10, mt:4}}>
                            <Typography
                                variant="h6"
                            >
                                Location
                            </Typography>
                            
                        </Box>
                    </Grid>
                    {
                        locate_list.map((e, index)=>(
                            <Grid item xs={12} key={index}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Box sx={{textAlign: "left", ml: 15, mt: 2}}>
                                            <TextField
                                                sx={{width: "100%"}}
                                                label={e.label}
                                                value={getValue(e.name) || ""}
                                                onChange={(v) => handleChangeInfo(e.name, v)}
                                                required={e.required}
                                                InputProps={{
                                                    startAdornment: (
                                                    <InputAdornment position="start">
                                                        {e.icon}
                                                    </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} sx={{alignSelf:'center'}}>
                                        <Box>
                                            <FormControlLabel
                                                control={<Android12Switch checked={getPublicCheck(e.name)} onChange={(v) => handleChangeCheck(e.name, v)}/>}
                                                label={getPublicCheck(e.name)?"Public":"Private"}
                                            />

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                                
                        ))
                    }
                    <Grid item xs={12}>
                        <Box sx={{textAlign: "left", ml: 15, mt: 2}}>
                            <Autocomplete
                            getOptionLabel={(option) => option.text}
                            options={countries}
                            value={userInfo.country ? getCountry(userInfo.country) : getCountry('AU')}
                            onChange={(e, v) => {
                                handleChangeInfo('country', v.value);
                            }}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                fullWidth
                                variant="outlined"
                                {...params}
                                size="small"
                                />
                            )}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ ml:15, mt:5}}>
                            <MintNFTView uri={myURI} getRDFString={getRDFString} afterMint={handleSubmit}/>
                        </Box>
                    </Grid>
                </Grid>
                </Stack>
                
            </Container>
        )
                            
    }
}