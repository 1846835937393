import { Alert, AlertTitle, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BSEARCH_URI } from "../common/config";
import { useGlobalState } from "../common/globalState";
import { personal_sign } from "../common/metamask";

import { Box } from "@mui/system";
import { useWeb3React } from "@web3-react/core";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { CreateHeader, request, setGlobalVal } from "./common-tool";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { RDFEditView } from "./RDFEdit";
import AddIcon from '@mui/icons-material/Add';


async function search_rdf(query, address, fee, callback_fn) {
    var params = {}
    var reason = `Bsearch Query=${query}`;
    params.query = query;
    params.address = `${address}`;
    params.free = "true";
    if (fee > 0) {
        params.free = "false"
    }

    if (fee > 0) {
        var seed = await new Promise(resolve => request(resolve, params.address, fee, reason));

        if (seed === '') {
            params.free = "true";
        }
        else {
            let vk = await personal_sign(seed, address);
            params.verification = vk;
            params.seed = seed;
        }
    }

    try {
        //alert(JSON.stringify(params));
        axios.post(
            BSEARCH_URI + "rdf_query", params
        )
            .then(response => {
                if (response && response.data) {
                    var result = response.data;

                    if (!result || Object.keys(result).length === 0) {
                        result = { 'error': "The data does not exist in ACCZIOM database." };
                    }

                    callback_fn(result);
                }

            })
    }
    catch (err) {
        var result = { 'error': err };
        callback_fn(result);
    }
}

export function RDFView(props) {
    const [state, dispatch] = useGlobalState();
    const [onSpin, setSpin] = useState(false);
    const [content, setContent] = useState({});
    const [query, setQuery] = useState("");
    const [fee, setFee] = useState(0);
    const [payFee, setPayFee] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deletedData, setDeletedData] = useState([]);
    //const [isDisabled, setDisabled] = useState(true);
    const context = useWeb3React()
    const { connector, chainId, error } = context
    let isDisabled = error || !chainId

    const handlePay = (e) => {
        setPayFee(state.predictedFee);
    }

    const handleAdd = (e) => {
        setOpenEditDialog(true);
    }

    const onEditDialogClose = (e) => {
        setOpenEditDialog(false);
    }

    const clickHyperLink = (e) => {
        let attr = e.target.attributes;
        dispatch({
            subTitle: attr.metalabel.nodeValue,
            subURI: attr.metauri.nodeValue,
            openDetails: true,
        })
    };

    const refreshPage = () => {
        let newContent = {};
        Object.keys(content).map(e => {
            newContent[e] = content[e];
        })
        setContent(newContent);
    }
    const clickAgree = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "approve_record", {
            address: context.account,
            record: id,
            yes_no: "yes"
        });
        console.log(`agree request=${res}`)
        if (res.data == true) {
            let y = record['__yes'] || 0;
            record['__yes'] = y + 1;
            refreshPage();
        }
    }

    const clickDisagree = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "approve_record", {
            address: context.account,
            record: id,
            yes_no: "no"
        });
        if (res.data == true) {
            let n = record['__no'] || 0;
            record['__no'] = n + 1;
            refreshPage();
        }
    }

    const clickDelete = async (id, record) => {
        if (!state.logged) {
            alert("Please sign in to use this function.")
            return;
        }
        let res = await axios.post(BSEARCH_URI + "delete_record", {
            address: context.account,
            record: id,
        });
        if (res.data == true) {
            setDeletedData([...deletedData, record]);
        }
        else {
            alert(res.data);
            return;
        }
    }

    useEffect(() => {
        var q = "";
        if (state.entityID !== "") {
            q = state.entityID
        }
        else if (state.entityName.length > 0) {
            q = state.entityName[0]
        }

        if (q === "") {
            setContent([]);
            setSpin(false);
            return;
        }

        var f = payFee || 0;
        if (query !== q) { setQuery(q); setFee(0); }
        if (f !== fee) setFee(f);

    }, [state.entityName, state.entityID, payFee, fee, query])

    useEffect(() => {
        if (query === "") return;
        let ff = fee
        if (query === state.rdfHistory.query && state.rdfHistory.fee >= fee) {
            setContent(state.rdfHistory.result);
            setSpin(false);
            if (state.rdfHistory.fee > 0) {
                setMounted(true);
            }
            return;
        }

        if (query !== state.rdfHistory.query) {
            ff = 0;
        }

        setSpin(true);

        var address = `${context.account}`
        search_rdf(query, address, ff, result => {
            var f = result.predicted_fee || 0;
            dispatch({
                rdfHistory: { query: query, fee: ff, result: result },
                predictedFee: f
            })
            delete result.predicted_fee
            var key_list = Object.keys(result)
            if (key_list.length === 0) {
                result.error = 'The data does not exist in Accziom Database.'
            }
            else {
                key_list.forEach(e => {
                    if (e !== "##order##") {
                        var props = result[e];
                        Object.keys(props).forEach(k => {
                            var val = props[k];
                            if (Array.isArray(val)) val = val[0];
                            if (val.value) val = val.value
                            if (Array.isArray(val)) val = val[0];
                            setGlobalVal(k, val, state, dispatch);
                        })
                    }
                })
            }
            setContent(result);
            setDeletedData([]);
            if (ff > 0) {
                setMounted(true);
            }
            setSpin(false);
        })

    }, [query, fee, context.account, state.rdfHistory.query, state.rdfHistory.fee])

    if (onSpin) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} />
            </Box>
        )
    } else {

        var ord = content['##order##'];
        return (
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
                    <Typography sx={{ color: "#146EB6", ml: "10px", fontSize: "16px", fontWeight: "700" }}>Accziom Database</Typography>
                    <Button
                        sx={{ borderRadius: "20px", padding: "5px 6px", bgcolor: "#2895EB", justifyContent: "flex-start", gap: "10px", "&:hover": { bgcolor: "#2895EB" } }}
                        disabled={isDisabled || mounted || !state.rdfHistory.result.uri}
                        onClick={handleAdd}
                    >
                        <AddIcon sx={{ width: "18px", height: "18px", bgcolor: "white", color: "black", borderRadius: "50%" }} />
                        <Typography sx={{ fontSize: "8px", color: "white" }}>Add records</Typography>
                    </Button>
                </Box>
                <Grid container spacing={2}>
                    { <><Grid item xs={12}>
                        <Typography variant='h5'>
                            Legal Entity Profile
                        </Typography>
                    </Grid>

                    <Grid item xs={9}>
                        <Typography sx={{ pl: 4, pr: 2, textAlign: "left" }}>
                            This pane displays the information of a legal entity derived from Australian Legal Entity Knowlege base.
                            It has two versions: Free and Standard version. In the Free version, only the data from public resources (e.g. ABR) is available. In the Standard version, all the data built up by the Accziom community is available in the display or can be called via our API.
                        </Typography>
                    </Grid> </>}

                    { <Grid item xs={3}>
                        <Box textAlign={'right'}>
                            .
                            <Button disabled={isDisabled || mounted || state.predictedFee === 0} variant="contained" color="success" onClick={handlePay} sx={{ borderRadius: 10, height: 40, width: '90%', mb: 2 }} >
                                <LockOpenIcon sx={{ mr: 2 }} />
                                Pay {state.predictedFee.toLocaleString('en-US')} MERc
                            </Button>
                            <Button disabled={isDisabled || mounted || !state.rdfHistory.result.uri} variant="contained" color="success" onClick={handleAdd} sx={{ borderRadius: 10, height: 40, width: '90%' }} >
                                <NoteAddIcon sx={{ mr: 2 }} />
                                Add Records
                            </Button>

                        </Box>

                    </Grid>}

                    <Grid item xs={12}>
                        {
                            content.error ?
                                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                    <Alert severity="info">
                                        <AlertTitle>Info</AlertTitle>
                                        {content.error}
                                    </Alert>
                                </Paper>
                                :
                                <Box sx={{ bgcolor: "#FAFAFA", padding: "26px" }}>
                                    <Box sx={{ bgcolor: "#FFF", py: "28px" }}>
                                        {CreateHeader(
                                            content,
                                            ord,
                                            1,
                                            true,
                                            {
                                                hyperlink: clickHyperLink,
                                                agree: clickAgree,
                                                disagree: clickDisagree,
                                                delete: clickDelete
                                            },
                                            deletedData
                                        )}
                                    </Box>
                                </Box>
                        }

                    </Grid>
                </Grid>
                <RDFEditView open={openEditDialog} handleClose={onEditDialogClose} uri={state.rdfHistory.result.uri} />
            </Box>

        )
    }
}